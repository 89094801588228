* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

/* Section Styling */
.section {
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Parallax scrolling */
  position: relative; /* For overlay positioning */
}

.section-1 {
  background-image: url('https://source.unsplash.com/random/1');
}

.section-2 {
  background-image: url('https://source.unsplash.com/random/2');
}

.section-3 {
  background-image: url('https://source.unsplash.com/random/3');
}

/* Overlay Styling */
.overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  padding: 20px;
  color: white;
  border-radius: 10px;
  position: relative; /* Positioned relative to section */
  text-align: center;
}

.overlay h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.overlay p {
  font-size: 1.5em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .overlay h1 {
    font-size: 2.2em;
  }

  .overlay p {
    font-size: 1.2em;
  }

  .section {
    height: 80vh; /* Adjust height for mobile screens */
  }
}
